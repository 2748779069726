// Personal Links
export const GITHUB = 'https://github.com/sivayogasubramanian';
export const LINKED_IN = 'https://www.linkedin.com/in/sivayogasubramanian';
export const EMAIL = 'mailto:sivayogasubramanian@gmail.com';
export const BLOG = 'https://blog.sivarn.com';

// Experience Links
export const CVWO = 'https://www.comp.nus.edu.sg/~vwo/';
export const AAC = 'https://www.comp.nus.edu.sg/~vwo/projects/2021-aac-aunqa.html';
export const SPARKS = 'https://www.comp.nus.edu.sg/~vwo/projects/2021-sparks.html';
export const ASCENDA = 'https://www.ascendaloyalty.com';
export const ASCENDA_OCBC = 'https://www.travelwithocbc.com';
export const ASCENDA_CTBC = 'https://ctbc-travel.kaligo.com';
export const ASCENDA_FAB = 'https://travel-booking.bankfab.com';
export const ASCENDA_AMEX_HYUNDAI = 'https://travel.myamexrewards.com';
export const ASCENDA_JAL = 'https://travel.jmbworldmarketplace.com';
export const ASCENDA_CHINA_EASTERN = 'https://www.travel-rewards-ceair.com';

// Project Links
export const STEPS = 'https://uvents.nus.edu.sg/event/20th-steps/module/CS3217';
export const STEPS_2 = 'https://uvents.nus.edu.sg/event/21st-steps/module/CS3216';
export const TEAMMATES = 'https://teammatesv4.appspot.com';
export const TEAMMATES_GITHUB = 'https://github.com/TEAMMATES/teammates';
export const TEAMMATES_GITHUB_PRS =
  'https://github.com/TEAMMATES/teammates/pulls?q=is%3Apr+author%3Asivayogasubramanian+is%3Aclosed';
export const ANNOTATO_GITHUB = 'https://github.com/Annotato/annotato';
export const ANNOTATO_DEMO_VIDEO = 'https://www.youtube.com/watch?v=Yn8lnJn2OEs';
export const PEGGLECLONE_GITHUB = 'https://github.com/sivayogasubramanian/PeggleClone';
export const CS2103T_TP_GITHUB = 'https://github.com/AY2122S1-CS2103T-W16-2/tp';
export const CS2103T_TP_DOCS = 'https://ay2122s1-cs2103t-w16-2.github.io/tp/';
export const JUST_TO_DO_IT_GITHUB = 'https://github.com/sivayogasubramanian/just-to-do-it';
export const CAZA_GITHUB = 'https://github.com/sivayogasubramanian/caza';
export const GIVING_COUPONS_GITHUB = 'https://github.com/Giving-Coupons/giving-coupons';
export const GIVING_COUPONS_VIDEO = 'https://www.youtube.com/watch?v=n02Co0pLkS0';

// Other Links
export const META = 'https://about.facebook.com/';
export const DSC = 'https://dsc.comp.nus.edu.sg/';
export const BYTEDANCE = 'https://www.bytedance.com/en/';
export const TIKTOK = 'https://www.tiktok.com/about?lang=en';
export const DSC_PROJECT_LIST = 'https://dsc.comp.nus.edu.sg/our-projects#list';
export const PROJECT_SIEW_DAI_VIDEO = 'https://www.youtube.com/watch?v=xI5ArYXHqjU';
export const CS1101S = 'https://nusmods.com/modules/CS1101S/programming-methodology';
export const CS3216 = 'https://nusmods.com/courses/CS3216/software-product-engineering-for-digital-markets`';
export const CS3216_23_STEPS = 'https://uvents.nus.edu.sg/event/23rd-steps/module/CS3216';

// Skill Icons
export const C_LINK = 'https://en.wikipedia.org/wiki/C_(programming_language)';
export const CPP_LINK = 'https://cplusplus.com/';
export const CSS_LINK = 'https://en.wikipedia.org/wiki/CSS';
export const GO_LINK = 'https://go.dev/';
export const JAVA_LINK = 'https://www.java.com/en/';
export const JAVASCRIPT_LINK = 'https://www.javascript.com/';
export const PYTHON_LINK = 'https://www.python.org/';
export const RUBY_LINK = 'https://www.ruby-lang.org/en/';
export const SASS_LINK = 'https://sass-lang.com/';
export const SQL_LINK = 'https://en.wikipedia.org/wiki/SQL';
export const SWIFT_LINK = 'https://www.swift.org/';
export const TYPESCRIPT_LINK = 'https://www.typescriptlang.org/';

export const ANGULAR_LINK = 'https://angular.io/';
export const BASH_LINK = 'https://www.gnu.org/software/bash/';
export const BOOTSTRAP_LINK = 'https://getbootstrap.com/';
export const CONFLUENCE_LINK = 'https://www.atlassian.com/software/confluence';
export const DIGITAL_OCEAN_LINK = 'https://www.digitalocean.com/';
export const DOCKER_LINK = 'https://www.docker.com/';
export const EXPRESS_LINK = 'https://expressjs.com/';
export const FIGMA_LINK = 'https://www.figma.com/';
export const FIREBASE_LINK = 'https://firebase.google.com/';
export const FLINK_LINK = 'https://flink.apache.org/';
export const GOOGLE_CLOUD_LINK = 'https://cloud.google.com/';
export const GIT_LINK = 'https://git-scm.com/';
export const GITHUB_LINK = 'https://github.com/';
export const GITLAB_LINK = 'https://gitlab.com/';
export const GRADLE_LINK = 'https://gradle.org/';
export const GRAFANA_LINK = 'https://grafana.com/';
export const GROOVY_LINK = 'https://groovy-lang.org/';
export const HAML_LINK = 'https://haml.info/';
export const HIVE_LINK = 'https://hive.apache.org/';
export const HTML_5_LINK = 'https://en.wikipedia.org/wiki/HTML';
export const JAVA_FX_LINK = 'https://www.oracle.com/java/technologies/javafx/';
export const JIRA_LINK = 'https://www.atlassian.com/software/jira/';
export const JUNIT_5_LINK = 'https://junit.org/junit5/';
export const KAFKA_LINK = 'https://kafka.apache.org/';
export const LATEX_LINK = 'https://www.latex-project.org/';
export const LUCID_LINK = 'https://www.lucidchart.com/';
export const MAC_OS_LINK = 'https://www.apple.com/macos/';
export const MATERIAL_UI_LINK = 'https://material-ui.com/';
export const MAVEN_LINK = 'https://maven.apache.org/';
export const MYSQL_LINK = 'https://www.mysql.com/';
export const NEXT_LINK = 'https://nextjs.org/';
export const NGINX_LINK = 'https://www.nginx.com/';
export const NODE_LINK = 'https://nodejs.org/en';
export const PREMIERE_PRO_LINK = 'https://www.adobe.com/sg/products/premiere.html';
export const PSQL_LINK = 'https://www.postgresql.org/';
export const RAILS_LINK = 'https://rubyonrails.org/';
export const REACT_LINK = 'https://reactjs.org/';
export const REDIS_LINK = 'https://redis.io/';
export const REDUX_LINK = 'https://redux.js.org/';
export const RSPEC_LINK = 'https://rspec.info/';
export const SEQUELIZE_LINK = 'https://sequelize.org/';
export const SPARK_LINK = 'https://spark.apache.org/';
export const TAILWIND_LINK = 'https://tailwindcss.com/';
export const UBUNTU_LINK = 'https://www.ubuntu.com/';
export const VIM_LINK = 'https://www.vim.org/';
export const WEBSOCKETS_LINK = 'https://en.wikipedia.org/wiki/WebSocket';
export const WINDOWS_LINK = 'https://www.microsoft.com/en-us/windows/';

// Documents
export const CS1101S_TEACHING_FEEDBACK = '/cs1101s-teaching-feedback.pdf';
