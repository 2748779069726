import { Element } from 'react-scroll';
import { getSkillIcon, SkillIcon } from '../../components/skill-icons';
import './Skill.scss';

const Skill = () => {
  const languages: SkillIcon[] = [
    getSkillIcon('Python'),
    getSkillIcon('C++'),
    getSkillIcon('Go'),
    getSkillIcon('Java'),
    getSkillIcon('JavaScript'),
    getSkillIcon('TypeScript'),
    getSkillIcon('Ruby'),
    getSkillIcon('Swift'),
    getSkillIcon('HTML 5'),
    getSkillIcon('CSS 3'),
    getSkillIcon('SASS'),
  ];

  const technologies: SkillIcon[] = [
    getSkillIcon('Node'),
    getSkillIcon('Rails'),

    getSkillIcon('Angular'),
    getSkillIcon('React'),
    getSkillIcon('Redux'),
    getSkillIcon('Next'),

    getSkillIcon('PSQL'),

    getSkillIcon('Firebase'),

    getSkillIcon('Docker'),
    getSkillIcon('Git'),
    getSkillIcon('Bash'),
    getSkillIcon('Vim'),
    getSkillIcon('Digital Ocean'),
  ];

  // const operatingSystems: SkillIcon[] = [getSkillIcon('MacOS'), getSkillIcon('Ubuntu'), getSkillIcon('Windows')];

  // const others: SkillIcon[] = [
  //   getSkillIcon('Figma'),
  //   getSkillIcon('Lucid'),
  //   getSkillIcon('LaTeX'),
  //   getSkillIcon('Jira'),
  //   getSkillIcon('Confluence'),
  //   getSkillIcon('Premiere Pro'),
  // ];

  return (
    <div className="app-skill">
      <Element name="skill">
        <h1>Skills.</h1>

        <p> The following lists are not in any particular order.</p>

        <h2>Languages</h2>

        <div className="app-skill-items">
          {languages.map((language, index) => (
            <div key={index}>
              <a href={language.link} target="_blank" rel="noopener noreferrer">
                <div>{language.icon}</div>
                <p>{language.name}</p>
              </a>
            </div>
          ))}
        </div>

        <h2>Technologies</h2>

        <div className="app-skill-items">
          {technologies.map((tech, index) => (
            <div key={index}>
              <a href={tech.link} target="_blank" rel="noopener noreferrer">
                <div>{tech.icon}</div>
                <p>{tech.name}</p>
              </a>
            </div>
          ))}
        </div>

        {/* <h2>Familiar Operating Systems</h2>

        <div className="app-skill-items">
          {operatingSystems.map((os, index) => (
            <div key={index}>
              <a href={os.link} target="_blank" rel="noopener noreferrer">
                <div>{os.icon}</div>
                <p>{os.name}</p>
              </a>
            </div>
          ))}
        </div> */}

        {/* <h2>Others</h2>

        <div className="app-skill-items">
          {others.map((other, index) => (
            <div key={index}>
              <a href={other.link} target="_blank" rel="noopener noreferrer">
                <div>{other.icon}</div>
                <p>{other.name}</p>
              </a>
            </div>
          ))}
        </div> */}
      </Element>
    </div>
  );
};

export default Skill;
