import React, { ReactElement } from 'react';
import { SkillIcon } from '../skill-icons';
import './ProjectItem.scss';

export interface ProjectItemLink {
  description: string;
  link: string;
}

export interface ProjectItemProps {
  title: string;
  projectLink?: string;
  period: string;
  context?: string;
  points: ReactElement[];
  linkDescription: string;
  links: ProjectItemLink[];
  languages?: SkillIcon[];
  technologies?: SkillIcon[];
  images?: string[];
  imageWidth?: string;
  imageHeight?: string;
  imageMobileWidth?: string;
  imageMobileHeight?: string;
  mobileFlexDirection?: 'row' | 'col';
}

const ProjectItem = (props: ProjectItemProps) => {
  const {
    title,
    projectLink,
    period,
    context,
    points,
    linkDescription,
    links,
    languages,
    technologies,
    images,
    imageWidth,
    imageHeight,
    imageMobileWidth,
    imageMobileHeight,
    mobileFlexDirection,
  } = props;

  return (
    <div className="app-project-item">
      <div className="app-experience-item-title">
        <h1>
          {projectLink ? (
            <a
              className="hover-underline-animation-reversed"
              href={projectLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </a>
          ) : (
            title
          )}
        </h1>
      </div>

      <p className="app-project-item-subtitle">{period}</p>

      {images && (
        <>
          <div className="app-project-images-container">
            {images.map((image, index) => (
              <img key={index} alt={title} src={image} width={imageWidth} height={imageHeight} />
            ))}
          </div>

          <div
            className="app-project-images-container-mobile"
            style={mobileFlexDirection === 'row' ? { flexDirection: 'row' } : { flexDirection: 'column' }}
          >
            {images.map((image, index) => (
              <img key={index} alt={title} src={image} width={imageMobileWidth} height={imageMobileHeight} />
            ))}
          </div>
        </>
      )}

      {context && <p className="app-project-item-subtitle">{context}</p>}

      <ul className="app-project-item-points">{points}</ul>

      {linkDescription && <p className="app-project-item-link-description">{linkDescription}</p>}

      <ul className="app-project-item-links">
        {links.map((link, index) => (
          <li key={index}>
            <a
              className="hover-underline-animation-reversed"
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.description}
            </a>
          </li>
        ))}
      </ul>

      {languages && (
        <>
          <p className="app-project-item-subtitle">Tech Stack</p>

          <div className="app-skill-items">
            {languages.map((language, index) => (
              <div key={index}>
                <a href={language.link} target="_blank" rel="noopener noreferrer">
                  <div>{language.icon}</div>
                  <p>{language.name}</p>
                </a>
              </div>
            ))}
            {technologies &&
              technologies.map((tech, index) => (
                <div key={index}>
                  <a href={tech.link} target="_blank" rel="noopener noreferrer">
                    <div>{tech.icon}</div>
                    <p>{tech.name}</p>
                  </a>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectItem;
