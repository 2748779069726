import React, { useState } from 'react';
import './Navbar.scss';
import { Link } from 'react-scroll';
import MediaQuery from 'react-responsive';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

export interface NavbarProps {
  isDarkMode: boolean;
  setIsDarkMode: () => void;
}

interface NavbarItem {
  title: string;
  target: string;
}

const Navbar = (props: NavbarProps) => {
  const { isDarkMode, setIsDarkMode } = props;
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const items: NavbarItem[] = [
    { title: 'About Me.', target: 'about' },
    // { title: 'Skills.', target: 'skill' },
    { title: 'Experience.', target: 'experience' },
    { title: 'Projects.', target: 'project' },
    // { title: 'Others.', target: 'other' },
    { title: 'Contact.', target: 'contact' },
  ];

  const links: JSX.Element = (
    <>
      {items.map((item, index) => (
        <li key={index}>
          <Link className="hover-underline-animation" to={item.target} smooth duration={500 + index * 300} offset={-30}>
            {item.title}
          </Link>
        </li>
      ))}
    </>
  );

  return (
    <>
      <nav className="app-navbar">
        <div className="app-navbar-title">
          <p>Siva.</p>
        </div>

        <ul className="app-navbar-links">{links}</ul>

        <div className="hamburger-menu">
          <DarkModeSwitch
            className="dark-mode-toggle-mobile"
            onChange={setIsDarkMode}
            checked={isDarkMode}
            size={23}
            moonColor="#b0befd"
            sunColor="#d1a62e"
          />

          <input id="menu__toggle" type="checkbox" onClick={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)} />

          <label className="menu__btn" htmlFor="menu__toggle">
            <span></span>
          </label>
        </div>

        <MediaQuery query="(max-width: 2600px)">
          <DarkModeSwitch
            className="dark-mode-toggle-web"
            onChange={setIsDarkMode}
            checked={isDarkMode}
            size={23}
            moonColor="#b0befd"
            sunColor="#d1a62e"
          />
        </MediaQuery>

        <MediaQuery query="(min-width: 2600px)">
          <DarkModeSwitch
            className="dark-mode-toggle-web"
            onChange={setIsDarkMode}
            checked={isDarkMode}
            size={27.6}
            moonColor="#b0befd"
            sunColor="#d1a62e"
          />
        </MediaQuery>
      </nav>

      {isHamburgerMenuOpen && <ul className="app-navbar-links-mobile">{links}</ul>}
    </>
  );
};

export default Navbar;
