import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import App from './App';
import './index.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOMClient.createRoot(document.getElementById('root') as Element).render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route element={<App />} path="/" />
        <Route element={<Navigate to="/" />} path="*" />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

serviceWorkerRegistration.unregister();
