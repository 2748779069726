export const hello: string[] = [
  'Hello',
  'Nǐ hǎo',
  'Assalamu alaikum',
  'Vanakkam',
  'Annyeonghaseyo',
  'Konnichiwa',
  'Ola',
  'Bonjour',
  'Xin chào',
  'Namaste',
  'Marhaba',
  'Zdraveite',
  'Hola',
  'Hafa adai',
  'God dag',
  'Hallo',
  'Dia dhuit',
  'Guten tag',
  'Yasou',
  'Shalom',
  'Jo napot',
  'Góðan dag',
  'Nde-ewo',
  'Selamat pagi',
  'Salve',
  'Sveiki',
  'Moïen',
  'Bonġu',
  'Niltze',
  'Salam',
  'Cześć',
  'Olá',
  'Bună ziua',
  'Zdravo',
  'Namaskar',
  'Ahoj',
  'Hujambo',
  'Ia orna',
  'Sawasdee',
  'Avuxeni',
  'Shwmae',
  'Sawubona',
  'Salem',
  'Privet',
].map((str) => str + '!');
