import React from 'react';
import './Footer.scss';
import Social from '../social/Social';

const Footer = () => {
  return (
    <div className="app-footer">
      <div className="app-footer-title">
        <p>Siva.</p>
      </div>

      <div className="app-footer-description">
        <p>Thank you for visiting! 🥰</p>
      </div>

      <Social />
    </div>
  );
};

export default Footer;
