import React from 'react';
import './About.scss';
import { Element } from 'react-scroll';

const About = () => {
  return (
    <div className="app-about">
      <Element name="about">
        <h1>About Me.</h1>

        <h2>
          As a recent computer science graduate from the National University of Singapore (NUS), my passion for software
          engineering has deepened significantly throughout my academic journey. I thrive in collaborative environments
          and take great pleasure in contributing to all aspects of the software engineering process.
        </h2>

        <h2>
          My ultimate goal is to create clean, secure, and maintainable code that positively impacts lives on a global
          scale. I believe in the transformative power of software engineering and stay updated on the latest
          technologies and industry best practices to create efficient systems.
        </h2>

        <h2>
          Beyond the warm glow of my monitors, I enjoy exploring my creative side through photography and videography.
          Whether I'm shooting or editing, I find it to be an exciting and rewarding endeavor.
        </h2>
      </Element>
    </div>
  );
};

export default About;
