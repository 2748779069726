import React from 'react';
import { BLOG, EMAIL, GITHUB, LINKED_IN } from '../../constants/link';
import './Social.scss';

const Social = () => {
  return (
    <div className="app-intro-social-links">
      <a className="hover-underline-animation-reversed" href={GITHUB} target="_blank" rel="noopener noreferrer">
        Github.
      </a>
      <a className="hover-underline-animation-reversed" href={LINKED_IN} target="_blank" rel="noopener noreferrer">
        LinkedIn.
      </a>
      <a className="hover-underline-animation-reversed" href={EMAIL} target="_blank" rel="noopener noreferrer">
        Email.
      </a>
      <a className="hover-underline-animation-reversed" href={BLOG} target="_blank" rel="noopener noreferrer">
        Blog.
      </a>
    </div>
  );
};

export default Social;
