import { ReactElement } from 'react';
import {
  ANGULAR_LINK,
  BASH_LINK,
  BOOTSTRAP_LINK,
  CONFLUENCE_LINK,
  CSS_LINK,
  C_LINK,
  DIGITAL_OCEAN_LINK,
  DOCKER_LINK,
  EXPRESS_LINK,
  FIGMA_LINK,
  FIREBASE_LINK,
  GITHUB_LINK,
  GITLAB_LINK,
  GIT_LINK,
  GOOGLE_CLOUD_LINK,
  GO_LINK,
  GRADLE_LINK,
  HAML_LINK,
  HTML_5_LINK,
  JAVASCRIPT_LINK,
  JAVA_FX_LINK,
  JAVA_LINK,
  MYSQL_LINK,
  JIRA_LINK,
  JUNIT_5_LINK,
  LATEX_LINK,
  LUCID_LINK,
  MAC_OS_LINK,
  MATERIAL_UI_LINK,
  NEXT_LINK,
  NGINX_LINK,
  PREMIERE_PRO_LINK,
  PSQL_LINK,
  PYTHON_LINK,
  RAILS_LINK,
  REACT_LINK,
  REDUX_LINK,
  RSPEC_LINK,
  RUBY_LINK,
  SASS_LINK,
  SEQUELIZE_LINK,
  SWIFT_LINK,
  TYPESCRIPT_LINK,
  UBUNTU_LINK,
  VIM_LINK,
  WEBSOCKETS_LINK,
  WINDOWS_LINK,
  REDIS_LINK,
  GRAFANA_LINK,
  TAILWIND_LINK,
  CPP_LINK,
  NODE_LINK,
  KAFKA_LINK,
  FLINK_LINK,
  HIVE_LINK,
  GROOVY_LINK,
  MAVEN_LINK,
  SPARK_LINK,
  SQL_LINK,
} from '../../constants/link';

import { ReactComponent as C } from '../skill-icons/languages/c.svg';
import { ReactComponent as Cpp } from '../skill-icons/languages/c++.svg';
import { ReactComponent as CSS } from '../skill-icons/languages/css.svg';
import { ReactComponent as Go } from '../skill-icons/languages/go.svg';
import { ReactComponent as Java } from '../skill-icons/languages/java.svg';
import { ReactComponent as JavaScript } from '../skill-icons/languages/js.svg';
import { ReactComponent as Python } from '../skill-icons/languages/python.svg';
import { ReactComponent as Ruby } from '../skill-icons/languages/ruby.svg';
import { ReactComponent as Sass } from '../skill-icons/languages/sass.svg';
import { ReactComponent as SQL } from '../skill-icons/languages/sql.svg';
import { ReactComponent as Swift } from '../skill-icons/languages/swift.svg';
import { ReactComponent as TypeScript } from '../skill-icons/languages/ts.svg';

import { ReactComponent as Angular } from '../skill-icons/technologies/angular.svg';
import { ReactComponent as Bash } from '../skill-icons/technologies/bash.svg';
import { ReactComponent as Bootstrap } from '../skill-icons/technologies/bootstrap.svg';
import { ReactComponent as Confluence } from '../skill-icons/technologies/confluence.svg';
import { ReactComponent as DigitalOcean } from '../skill-icons/technologies/digitalocean.svg';
import { ReactComponent as Docker } from '../skill-icons/technologies/docker.svg';
import { ReactComponent as Express } from '../skill-icons/technologies/express.svg';
import { ReactComponent as Figma } from '../skill-icons/technologies/figma.svg';
import { ReactComponent as Firebase } from '../skill-icons/technologies/firebase.svg';
import { ReactComponent as Flink } from '../skill-icons/technologies/flink.svg';
import { ReactComponent as GCP } from '../skill-icons/technologies/gcp.svg';
import { ReactComponent as Git } from '../skill-icons/technologies/git.svg';
import { ReactComponent as Github } from '../skill-icons/technologies/github.svg';
import { ReactComponent as GitLab } from '../skill-icons/technologies/gitlab.svg';
import { ReactComponent as Gradle } from '../skill-icons/technologies/gradle.svg';
import { ReactComponent as Grafana } from '../skill-icons/technologies/grafana.svg';
import { ReactComponent as Groovy } from '../skill-icons/technologies/groovy.svg';
import { ReactComponent as HAML } from '../skill-icons/technologies/haml.svg';
import { ReactComponent as Hive } from '../skill-icons/technologies/hive.svg';
import { ReactComponent as HTML } from '../skill-icons/technologies/html.svg';
import { ReactComponent as JavaFX } from '../skill-icons/technologies/javafx.svg';
import { ReactComponent as Jira } from '../skill-icons/technologies/jira.svg';
import { ReactComponent as JUnit5 } from '../skill-icons/technologies/junit5.svg';
import { ReactComponent as Kafka } from '../skill-icons/technologies/kafka.svg';
import { ReactComponent as Latex } from '../skill-icons/technologies/latex.svg';
import { ReactComponent as Lucid } from '../skill-icons/technologies/lucid.svg';
import { ReactComponent as MacOS } from '../skill-icons/technologies/macos.svg';
import { ReactComponent as MaterialUI } from '../skill-icons/technologies/materialui.svg';
import { ReactComponent as Maven } from '../skill-icons/technologies/maven.svg';
import { ReactComponent as MySQL } from '../skill-icons/technologies/mysql.svg';
import { ReactComponent as Next } from '../skill-icons/technologies/next.svg';
import { ReactComponent as Nginx } from '../skill-icons/technologies/nginx.svg';
import { ReactComponent as Node } from '../skill-icons/technologies/node.svg';
import { ReactComponent as PremierePro } from '../skill-icons/technologies/premierepro.svg';
import { ReactComponent as PSQL } from '../skill-icons/technologies/psql.svg';
import { ReactComponent as Rails } from '../skill-icons/technologies/rails.svg';
import { ReactComponent as React } from '../skill-icons/technologies/react.svg';
import { ReactComponent as Redis } from '../skill-icons/technologies/redis.svg';
import { ReactComponent as Redux } from '../skill-icons/technologies/redux.svg';
import { ReactComponent as RSpec } from '../skill-icons/technologies/rspec.svg';
import { ReactComponent as Sequelize } from '../skill-icons/technologies/sequelize.svg';
import { ReactComponent as Spark } from '../skill-icons/technologies/spark.svg';
import { ReactComponent as Tailwind } from '../skill-icons/technologies/tailwind.svg';
import { ReactComponent as Ubuntu } from '../skill-icons/technologies/ubuntu.svg';
import { ReactComponent as Vim } from '../skill-icons/technologies/vim.svg';
import { ReactComponent as Websockets } from '../skill-icons/technologies/websockets.svg';
import { ReactComponent as Windows } from '../skill-icons/technologies/windows.svg';

export type SkillIcon = {
  name: string;
  icon: ReactElement;
  link: string;
};

const languages: SkillIcon[] = [
  {
    name: 'C',
    icon: <C />,
    link: C_LINK,
  },
  {
    name: 'C++',
    icon: <Cpp />,
    link: CPP_LINK,
  },
  {
    name: 'CSS 3',
    icon: <CSS />,
    link: CSS_LINK,
  },
  {
    name: 'Go',
    icon: <Go />,
    link: GO_LINK,
  },
  {
    name: 'Java',
    icon: <Java />,
    link: JAVA_LINK,
  },
  {
    name: 'JavaScript',
    icon: <JavaScript />,
    link: JAVASCRIPT_LINK,
  },

  {
    name: 'Python',
    icon: <Python />,
    link: PYTHON_LINK,
  },
  {
    name: 'Ruby',
    icon: <Ruby />,
    link: RUBY_LINK,
  },
  {
    name: 'SASS',
    icon: <Sass />,
    link: SASS_LINK,
  },
  {
    name: 'SQL',
    icon: <SQL />,
    link: SQL_LINK,
  },
  {
    name: 'Swift',
    icon: <Swift />,
    link: SWIFT_LINK,
  },
  {
    name: 'TypeScript',
    icon: <TypeScript />,
    link: TYPESCRIPT_LINK,
  },
];

const technologies: SkillIcon[] = [
  { name: 'Angular', icon: <Angular />, link: ANGULAR_LINK },
  { name: 'Bash', icon: <Bash />, link: BASH_LINK },
  { name: 'Bootstrap', icon: <Bootstrap />, link: BOOTSTRAP_LINK },
  {
    name: 'Confluence',
    icon: <Confluence />,
    link: CONFLUENCE_LINK,
  },
  { name: 'Digital Ocean', icon: <DigitalOcean />, link: DIGITAL_OCEAN_LINK },
  { name: 'Docker', icon: <Docker />, link: DOCKER_LINK },
  { name: 'Express', icon: <Express />, link: EXPRESS_LINK },
  { name: 'Figma', icon: <Figma />, link: FIGMA_LINK },
  { name: 'Firebase', icon: <Firebase />, link: FIREBASE_LINK },
  { name: 'Flink', icon: <Flink />, link: FLINK_LINK },
  { name: 'Google Cloud', icon: <GCP />, link: GOOGLE_CLOUD_LINK },
  { name: 'Git', icon: <Git />, link: GIT_LINK },
  { name: 'Github', icon: <Github />, link: GITHUB_LINK },
  { name: 'Gitlab', icon: <GitLab />, link: GITLAB_LINK },
  { name: 'Gradle', icon: <Gradle />, link: GRADLE_LINK },
  { name: 'Grafana', icon: <Grafana />, link: GRAFANA_LINK },
  { name: 'Groovy', icon: <Groovy />, link: GROOVY_LINK },
  { name: 'HAML', icon: <HAML />, link: HAML_LINK },
  { name: 'Hive', icon: <Hive />, link: HIVE_LINK },
  { name: 'HTML 5', icon: <HTML />, link: HTML_5_LINK },
  { name: 'JavaFX', icon: <JavaFX />, link: JAVA_FX_LINK },
  { name: 'Jira', icon: <Jira />, link: JIRA_LINK },
  { name: 'JUnit 5', icon: <JUnit5 />, link: JUNIT_5_LINK },
  { name: 'Kafka', icon: <Kafka />, link: KAFKA_LINK },
  { name: 'LaTeX', icon: <Latex />, link: LATEX_LINK },
  { name: 'Lucid', icon: <Lucid />, link: LUCID_LINK },
  { name: 'MacOS', icon: <MacOS />, link: MAC_OS_LINK },
  { name: 'MaterialUI', icon: <MaterialUI />, link: MATERIAL_UI_LINK },
  { name: 'Maven', icon: <Maven />, link: MAVEN_LINK },
  { name: 'MySQL', icon: <MySQL />, link: MYSQL_LINK },
  { name: 'Next', icon: <Next />, link: NEXT_LINK },
  { name: 'Nginx', icon: <Nginx />, link: NGINX_LINK },
  { name: 'Node', icon: <Node />, link: NODE_LINK },
  { name: 'Premiere Pro', icon: <PremierePro />, link: PREMIERE_PRO_LINK },
  { name: 'PSQL', icon: <PSQL />, link: PSQL_LINK },
  { name: 'Rails', icon: <Rails />, link: RAILS_LINK },
  { name: 'React', icon: <React />, link: REACT_LINK },
  { name: 'Redis', icon: <Redis />, link: REDIS_LINK },
  { name: 'Redux', icon: <Redux />, link: REDUX_LINK },
  { name: 'RSpec', icon: <RSpec />, link: RSPEC_LINK },
  { name: 'Sequelize', icon: <Sequelize />, link: SEQUELIZE_LINK },
  { name: 'Spark', icon: <Spark />, link: SPARK_LINK },
  { name: 'Tailwind', icon: <Tailwind />, link: TAILWIND_LINK },
  { name: 'Ubuntu', icon: <Ubuntu />, link: UBUNTU_LINK },
  { name: 'Vim', icon: <Vim />, link: VIM_LINK },
  { name: 'Websockets', icon: <Websockets />, link: WEBSOCKETS_LINK },
  { name: 'Windows', icon: <Windows />, link: WINDOWS_LINK },
];

const skills: SkillIcon[] = [...languages, ...technologies];

export function getSkillIcon(name: string): SkillIcon {
  return skills.find((skill) => skill.name === name)!;
}
