import React, { ReactElement } from 'react';
import { SkillIcon } from '../skill-icons/index';
import './ExperienceItem.scss';

export interface ExperienceItemLink {
  description: string;
  link: string;
}

export interface ExperienceItemProps {
  title: string;
  company: string;
  companyUrl: string;
  period: string;
  points?: ReactElement[];
  linkDescription?: string;
  links?: ExperienceItemLink[];
  languages?: SkillIcon[];
  technologies?: SkillIcon[];
  images?: string[];
  imageWidth?: string;
  imageHeight?: string;
  imageMobileWidth?: string;
  imageMobileHeight?: string;
  mobileFlexDirection?: 'row' | 'col';
}

const ExperienceItem = (props: ExperienceItemProps) => {
  const {
    title,
    company,
    companyUrl,
    period,
    points,
    linkDescription,
    links,
    languages,
    technologies,
    images,
    imageWidth,
    imageHeight,
    imageMobileWidth,
    imageMobileHeight,
    mobileFlexDirection,
  } = props;

  return (
    <div className="app-experience-item">
      <div className="app-experience-item-title">
        <h1>{title}</h1>
        <h1>
          @{' '}
          <a className="hover-underline-animation-reversed" href={companyUrl} target="_blank" rel="noopener noreferrer">
            {company}
          </a>
        </h1>
      </div>

      <p className="app-experience-item-subtitle">{period}</p>

      {images && (
        <>
          <div className="app-experience-images-container">
            {images.map((image, index) => (
              <img key={index} alt={title} src={image} width={imageWidth} height={imageHeight} />
            ))}
          </div>

          <div
            className="app-experience-images-container-mobile"
            style={mobileFlexDirection === 'row' ? { flexDirection: 'row' } : { flexDirection: 'column' }}
          >
            {images.map((image, index) => (
              <img key={index} alt={title} src={image} width={imageMobileWidth} height={imageMobileHeight} />
            ))}
          </div>
        </>
      )}

      {points && <ul>{points}</ul>}

      {linkDescription && <p className="app-experience-item-link-description">{linkDescription}</p>}

      {links && (
        <ul className="app-experience-item-links">
          {links.map((link, index) => (
            <li key={index}>
              <a
                className="hover-underline-animation-reversed"
                href={link.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.description}
              </a>
            </li>
          ))}
        </ul>
      )}

      {languages && (
        <>
          <p className="app-experience-item-subtitle">Tech Stack</p>
          <div className="app-skill-items">
            {languages.map((language, index) => (
              <div key={index}>
                <a href={language.link} target="_blank" rel="noopener noreferrer">
                  <div>{language.icon}</div>
                  <p>{language.name}</p>{' '}
                </a>
              </div>
            ))}
            {technologies &&
              technologies.map((tech, index) => (
                <div key={index}>
                  <a href={tech.link} target="_blank" rel="noopener noreferrer">
                    <div>{tech.icon}</div>
                    <p>{tech.name}</p>
                  </a>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ExperienceItem;
