import { Element } from 'react-scroll';
import ProjectItem from '../../components/project-item/ProjectItem';
import { getSkillIcon } from '../../components/skill-icons';
// import images from '../../constants/image';
import {
  ANNOTATO_DEMO_VIDEO,
  ANNOTATO_GITHUB,
  CAZA_GITHUB,
  CS2103T_TP_DOCS,
  CS2103T_TP_GITHUB,
  GIVING_COUPONS_GITHUB,
  GIVING_COUPONS_VIDEO,
  JUST_TO_DO_IT_GITHUB,
  PEGGLECLONE_GITHUB,
  STEPS,
  STEPS_2,
  TEAMMATES,
  TEAMMATES_GITHUB,
  TEAMMATES_GITHUB_PRS,
} from '../../constants/link';
import './Project.scss';
import { useState } from 'react';

const Project = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="app-project">
      <Element name="project">
        <h1>Projects.</h1>

        <ProjectItem
          title="Giving Coupons"
          period="Sep 2022 - Nov 2022"
          // images={[images.givingCoupons]}
          // imageHeight="35%"
          // imageWidth="35%"
          // imageMobileHeight="100%"
          // imageMobileWidth="100%"
          context="Built for CS3216: Software Product Engineering for Digital Markets."
          points={[
            <li key={1}>
              Developed Giving Coupons - a social enterprise project that aims to increase fundraising donations and
              raise awareness about charities a primary donor wishes to support by distributing coupons.Coupon
              recipients get to choose which charity receives the money specified and can choose to make a personal
              contribution if they wish to do so.
            </li>,
            <li key={2}>
              Giving Coupons was fortunate to have{' '}
              <a
                className="hover-underline-animation-reversed"
                href={STEPS_2}
                target="_blank"
                rel="noopener noreferrer"
              >
                won the second place
              </a>{' '}
              for the 21st SoC Term Project Showcase (STePS). This project was built by a team of 5 with me as the team
              lead and a full-stack developer.
            </li>,
            <li key={3}>
              Built as part of CS3216's group project component which is one of the hardest Software Engineering module
              NUS School of Computing has to offer which has a highly selective admission process.
            </li>,
          ]}
          linkDescription="Check out the Github repository and the demo video"
          links={[
            { description: 'Github repository', link: GIVING_COUPONS_GITHUB },
            { description: 'Promotional video', link: GIVING_COUPONS_VIDEO },
          ]}
          languages={[getSkillIcon('TypeScript'), getSkillIcon('Ruby')]}
          technologies={[
            getSkillIcon('Next'),
            getSkillIcon('MaterialUI'),
            getSkillIcon('Rails'),
            getSkillIcon('Firebase'),
            getSkillIcon('PSQL'),
            getSkillIcon('Git'),
            getSkillIcon('Github'),
            getSkillIcon('Docker'),
            getSkillIcon('Nginx'),
            getSkillIcon('Digital Ocean'),
          ]}
        />

        <ProjectItem
          title="Teammates"
          projectLink={TEAMMATES}
          period="May 2022 - Aug 2022"
          context="Done as part of CP3108B: Independent Work Module @ NUS-OSS"
          // images={[images.teammates]}
          // imageHeight="35%"
          // imageWidth="35%"
          // imageMobileHeight="100%"
          // imageMobileWidth="100%"
          points={[
            <li key={1}>
              Developed several new features and fixed existing bugs for this open source feedback management system for
              education used by more than 800,000 users from over 1,100 universities from 100 countries across the
              globe.
            </li>,
            <li key={2}>My contributions were mostly in the Angular frontend with some backend work in Java.</li>,
          ]}
          linkDescription="Check out the Github repository and my pull requests"
          links={[
            { description: 'Github repository', link: TEAMMATES_GITHUB },
            { description: 'My PRs', link: TEAMMATES_GITHUB_PRS },
          ]}
          languages={[getSkillIcon('TypeScript'), getSkillIcon('SASS'), getSkillIcon('Java')]}
          technologies={[
            getSkillIcon('Angular'),
            getSkillIcon('Google Cloud'),
            getSkillIcon('Bootstrap'),
            getSkillIcon('Gradle'),
            getSkillIcon('Git'),
            getSkillIcon('Github'),
          ]}
        />

        <ProjectItem
          title="Annotato"
          period="Mar 2022 - Apr 2022"
          context="Built for CS3217: Software Engineering on Modern Application Platforms"
          // images={[images.annotatoLogin, images.annotatoEdit]}
          // imageHeight="20%"
          // imageWidth="20%"
          // imageMobileHeight="45%"
          // imageMobileWidth="48%"
          // mobileFlexDirection="row"
          points={[
            <li key={1}>
              Developed an iPad application that supports PDF annotations, real-time multi-user collaboration, offline
              synchronisation and markdown, and Apple pencil input formats.
            </li>,
            <li key={2}>
              Annotato was fortunate to have{' '}
              <a className="hover-underline-animation-reversed" href={STEPS} target="_blank" rel="noopener noreferrer">
                won the second place
              </a>{' '}
              for the 20th SoC Term Project Showcase (STePS). This project was built by a team of 4 with me as the team
              lead and the backend developer.
            </li>,
            <li key={3}>
              Built as part of CS3217's group project component which is one of the hardest Software Engineering module
              NUS School of Computing has to offer which has a highly selective admission process.
            </li>,
          ]}
          linkDescription="Check out the Github repository and the demo video"
          links={[
            { description: 'Github repository', link: ANNOTATO_GITHUB },
            { description: 'Demo video', link: ANNOTATO_DEMO_VIDEO },
          ]}
          languages={[getSkillIcon('Swift')]}
          technologies={[
            getSkillIcon('Websockets'),
            getSkillIcon('PSQL'),
            getSkillIcon('Git'),
            getSkillIcon('Github'),
            getSkillIcon('Digital Ocean'),
          ]}
        />

        <ProjectItem
          title="PeggleClone"
          period="Jan 2022 - Feb 2022"
          context="Built for CS3217: Software Engineering on Modern Application Platforms"
          // images={[images.peggle, images.peggleDesigner]}
          // imageHeight="20%"
          // imageWidth="20%"
          // imageMobileHeight="45%"
          // imageMobileWidth="48%"
          // mobileFlexDirection="row"
          points={[
            <li key={1}>
              Engineered self-written physics and game engines to build a SwiftUI clone of the popular iPad game Peggle.
            </li>,
            <li key={2}>
              Built as part of CS3217's individual project component which is one of the hardest Software Engineering
              module NUS School of Computing has to offer which has a highly selective admission process.
            </li>,
          ]}
          linkDescription="Check out the Github repository"
          links={[{ description: 'Github repository', link: PEGGLECLONE_GITHUB }]}
          languages={[getSkillIcon('Swift')]}
          technologies={[getSkillIcon('Git'), getSkillIcon('Github')]}
        />

        {!showMore && (
          <p>
            <button className="hover-underline-animation-reversed" onClick={() => setShowMore(!showMore)}>
              Show More &darr;
            </button>
          </p>
        )}

        {showMore && (
          <>
            <ProjectItem
              title="Caza"
              period="Aug 2022 - Sep 2022"
              context="Built for CS3216: Software Product Engineering for Digital Markets."
              points={[
                <li key={1}>
                  Developed Caza - an internship and job application tracker for computing students developed by
                  computing students.
                </li>,
                <li key={2}>
                  This project was a proof of concept of our idea which was done in 2 weeks by a group of 4 members. Our
                  MVP had good feedback from the teaching team.
                </li>,
                <li key={3}>
                  Built as part of CS3216's group project component which is one of the hardest Software Engineering
                  module NUS School of Computing has to offer which has a highly selective admission process.
                </li>,
              ]}
              linkDescription="Check out the Github repository."
              links={[{ description: 'Github repository', link: CAZA_GITHUB }]}
              languages={[getSkillIcon('TypeScript')]}
              technologies={[
                getSkillIcon('Next'),
                getSkillIcon('Tailwind'),
                getSkillIcon('Firebase'),
                getSkillIcon('PSQL'),
                getSkillIcon('Git'),
                getSkillIcon('Github'),
              ]}
            />

            <ProjectItem
              title="BobTheBistroBoss"
              period="Sep 2021 - Nov 2021"
              context="Built for CS2103T: Software Engineering"
              // images={[images.btbb, images.bttbInventory]}
              // imageHeight="30%"
              // imageWidth="30%"
              // imageMobileHeight="100%"
              // imageMobileWidth="100%"
              points={[
                <li key={1}>
                  Created a CLI desktop application for home chefs to manage orders and inventory. This project was
                  built by a team of 5 with me as a full-stack developer.
                </li>,
                <li key={2}>
                  Built as part of CS2103T's group project component which was my first Software Engineering module and
                  had a great role in shaping my understanding of software engineering.
                </li>,
              ]}
              linkDescription="Check out the Github repository and the project documentation"
              links={[
                { description: 'Github repository', link: CS2103T_TP_GITHUB },
                { description: 'Documentation', link: CS2103T_TP_DOCS },
              ]}
              languages={[getSkillIcon('Java')]}
              technologies={[
                getSkillIcon('JavaFX'),
                getSkillIcon('JUnit 5'),
                getSkillIcon('Gradle'),
                getSkillIcon('Git'),
                getSkillIcon('Github'),
              ]}
            />

            <ProjectItem
              title="Just To-Do It"
              period="Dec 2020 - Jan 2021"
              context="Built for CVWO internship applications"
              // images={[images.jtti]}
              // imageHeight="35%"
              // imageWidth="35%"
              // imageMobileHeight="100%"
              // imageMobileWidth="100%"
              points={[
                <li key={1}>
                  Just To-Do It is a todo manager that is simple to use and helps you organise your day a little better.
                  With features like tagging, searching and subtodo creation, it helps you plan your day out and
                  organise your tasks so that you can focus on the task at hand.
                </li>,
                <li key={2}>
                  Built as a take home assignment for CVWO internship applications. This is where my coding passion
                  ignited.
                </li>,
              ]}
              linkDescription="Check out the Github repository"
              links={[{ description: 'Github repository', link: JUST_TO_DO_IT_GITHUB }]}
              languages={[getSkillIcon('JavaScript'), getSkillIcon('Ruby')]}
              technologies={[
                getSkillIcon('React'),
                getSkillIcon('Redux'),
                getSkillIcon('MaterialUI'),
                getSkillIcon('Rails'),
                getSkillIcon('PSQL'),
                getSkillIcon('Git'),
                getSkillIcon('Github'),
              ]}
            />

            <p>
              <button className="hover-underline-animation-reversed" onClick={() => setShowMore(!showMore)}>
                Show Less &uarr;
              </button>
            </p>
          </>
        )}
      </Element>
    </div>
  );
};

export default Project;
