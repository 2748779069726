// import annotatoLogin from '../assets/annotato-login.jpg';
// import annotatoEdit from '../assets/annotato-edit.jpg';
// import ascenda from '../assets/ascenda.jpg';
// import btbb from '../assets/btbb.jpg';
// import bttbInventory from '../assets/bttb-inventory.jpg';
import coding from '../assets/coding.jpg';
// import jtti from '../assets/jtti.jpg';
// import peggle from '../assets/peggle.jpg';
// import peggleDesigner from '../assets/peggle-designer.jpg';
// import teammates from '../assets/teammates.jpg';
// import dsc from '../assets/dsc.jpg';
// import bytedance from '../assets/bytedance.jpeg';
// import givingCoupons from '../assets/gc-landing.png';

const images = {
  // annotatoLogin,
  // annotatoEdit,
  // ascenda,
  // btbb,
  // bttbInventory,
  coding,
  // jtti,
  // peggle,
  // peggleDesigner,
  // teammates,
  // dsc,
  // bytedance,
  // givingCoupons,
};

export default images;
