import React from 'react';
import { Element } from 'react-scroll';
import './Contact.scss';

const Contact = () => {
  return (
    <div className="app-contact">
      <Element name="contact">
        <h1>Contact.</h1>

        <p>
          Want to get in touch? I'd love to hear from you! Fill this form and send it in. I'll get back to you as soon
          as possible.
        </p>

        <form name="contact v1" method="post">
          <input type="hidden" name="form-name" value="contact v1" />

          <div hidden>
            <input name="bot-field" />
          </div>

          <p>
            <label>
              Name
              <br />
              <input type="text" name="name" required />
            </label>
          </p>

          <p>
            <label>
              Email
              <br />
              <input type="email" name="email" required />
            </label>
          </p>

          <p>
            <label>
              Message
              <br />
              <textarea name="message" required />
            </label>
          </p>

          <p>
            <button className="hover-underline-animation-reversed" type="submit">
              Send it in!
            </button>
          </p>
        </form>
      </Element>
    </div>
  );
};

export default Contact;
