import React, { useState, useEffect } from 'react';
import TypewriterComponent from 'typewriter-effect';
import './Intro.scss';
import { hello } from '../../constants/hello';
import images from '../../constants/image';
import { Social } from '../../components';

const Intro = () => {
  const HELLO_TEXT_START_DELAY = 500;
  const [hasHelloTextStarted, setHasHelloTextStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHasHelloTextStarted(true);
    }, HELLO_TEXT_START_DELAY);
  }, []);

  return (
    <div className="app-intro-outer">
      <img className="profile-photo-mobile" alt="Profile" src={images.coding} />

      <div className="app-intro">
        {/* <h1 className="app-intro-emoji">{''}</h1> */}

        <div className="app-intro-hello">
          <TypewriterComponent
            options={{
              strings: hello,
              autoStart: hasHelloTextStarted,
              loop: true,
              wrapperClassName: 'hello-text',
              cursorClassName: 'Typewriter__cursor cursor',
            }}
          />
        </div>

        <h1 className="app-intro-name">I'm Siva</h1>

        <h2 className="app-intro-description">
          I have a passion for tackling challenging software engineering problems both as a hobby and in my professional
          work. That's me over there looking very serious.
        </h2>

        <h2 className="app-intro-description app-intro-current-description">
          Currently a Software Engineer at TikTok.
        </h2>

        <Social />
      </div>

      <img className="profile-photo" alt="Profile" src={images.coding} />
    </div>
  );
};

export default Intro;
