import { Element } from 'react-scroll';
import ExperienceItem from '../../components/experience-item/ExperienceItem';
import { getSkillIcon } from '../../components/skill-icons';
// import images from '../../constants/image';
import {
  AAC,
  ASCENDA,
  ASCENDA_AMEX_HYUNDAI,
  ASCENDA_CHINA_EASTERN,
  ASCENDA_CTBC,
  ASCENDA_FAB,
  ASCENDA_JAL,
  ASCENDA_OCBC,
  BYTEDANCE,
  CVWO,
  SPARKS,
  TIKTOK,
} from '../../constants/link';
import './Experience.scss';

const Experience = () => {
  return (
    <div className="app-experience">
      <Element name="experience">
        <h1>Experience.</h1>

        <ExperienceItem
          title="Software Engineer"
          company="TikTok"
          companyUrl={TIKTOK}
          period="Jun 2024 - Present"
          points={[
            <li key={1}>
              Developing an async extension in Go for the synchronous C++ traffic security platform, enabling advanced
              rule execution and decision-making processes.
            </li>,
            <li key={2}>
              Developed PySpark jobs to analyse Hive dumps from live MySQL databases, identifying 80% of unused data
              cached in Redis for the C++ traffic security platform, optimising storage costs and performance by
              resolving the Redis big key problem.
            </li>,
          ]}
          languages={[getSkillIcon('Go'), getSkillIcon('C++'), getSkillIcon('Python')]}
          technologies={[
            getSkillIcon('Kafka'),
            getSkillIcon('Spark'),
            getSkillIcon('Hive'),
            getSkillIcon('Redis'),
            getSkillIcon('Git'),
            getSkillIcon('Gitlab'),
          ]}
        />
        <ExperienceItem
          title="Software Engineer Intern"
          company="TikTok"
          companyUrl={TIKTOK}
          period="Jan 2024 - May 2024"
          points={[
            <li key={1}>
              Developed an MVP Flink-based near real-time rule engine for a traffic security platform, enabling a
              comparative analysis of real-time and async processing, ultimately leading to the adoption of an async
              engine based on product requirements.
            </li>,
            <li key={2}>
              Developed a Go event bus middleware to route events to a custom Kafka message queue, facilitating usage of
              existing Kafka Flink adapters for faster MVP delivery, while also implementing RPC/Redis integrations,
              testing core components, and establishing group chat alerts as the foundation for a dedicated alerts and
              response microservice.
            </li>,
          ]}
          languages={[getSkillIcon('Java'), getSkillIcon('Groovy'), getSkillIcon('Go')]}
          technologies={[
            getSkillIcon('Flink'),
            getSkillIcon('Kafka'),
            getSkillIcon('Redis'),
            getSkillIcon('Hive'),
            getSkillIcon('Maven'),
            getSkillIcon('Git'),
            getSkillIcon('Gitlab'),
          ]}
        />

        <ExperienceItem
          title="Software Engineer Intern"
          company="ByteDance"
          companyUrl={BYTEDANCE}
          period="Nov 2022 - Jul 2023"
          // images={[images.bytedance]}
          // imageHeight="35%"
          // imageWidth="35%"
          // imageMobileHeight="100%"
          // imageMobileWidth="100%"
          points={[
            <li key={1}>
              Architected, developed and deployed several new microservices, facilitating seamless bank transfers or
              credit/debit card payments between ByteDance and esteemed financial institutions like Citibank,
              Kasikornbank, AppotaPay, and Maybank.
            </li>,
            <li key={2}>
              Integrated new endpoints with 90% unit test coverage by leveraging the capabilities of Citibank and JP
              Morgan backend systems, introducing enhanced functionalities for the ByteDance backend system.
            </li>,
            <li key={3}>
              Implemented request forwarding services and facilitated the deployment of microservices onto a new
              cluster, playing a key role in TikTok's Project Texas to enhance the security of US data.
            </li>,
            <li key={4}>
              Assumed on-call responsibilities for a week, diligently monitoring critical alerts and warnings across the
              entire backend systems of banking microservices, and successfully developing effective mitigation plans in
              response.
            </li>,
          ]}
          languages={[getSkillIcon('Go')]}
          technologies={[
            getSkillIcon('MySQL'),
            getSkillIcon('Redis'),
            getSkillIcon('Grafana'),
            getSkillIcon('Git'),
            getSkillIcon('Gitlab'),
          ]}
        />

        <ExperienceItem
          title="Software Engineer Intern"
          company="Ascenda"
          companyUrl={ASCENDA}
          period="May 2022 - Aug 2022"
          // images={[images.ascenda]}
          // imageHeight="35%"
          // imageWidth="35%"
          // imageMobileHeight="100%"
          // imageMobileWidth="100%"
          points={[
            <li key={1}>
              Migrated deprecated AngularJS frontend to Angular 12 for Ascenda's global loyalty platforms, ensuring
              compatibility with Google's patches and maintaining security standards for major banks and airlines
              handling sensitive information.
            </li>,
            <li key={2}>
              Engineered highly customizable and reusable Angular components, empowering developers to seamlessly
              integrate client-specific functionality and styling requirements, minimizing code duplication and
              enhancing scalability.
            </li>,
            <li key={3}>
              Collaborated with the team by conducting thorough code reviews, ensuring adherence to high-quality coding
              standards, thereby reducing QA time and enabling the team to deploy new features rapidly.
            </li>,
          ]}
          linkDescription="Ascenda serves many clients worldwide. Here are some of the clients' websites powered by Ascenda."
          links={[
            { description: 'OCBC', link: ASCENDA_OCBC },
            { description: 'CTBC', link: ASCENDA_CTBC },
            { description: 'FAB', link: ASCENDA_FAB },
            { description: 'AMEX x Hyundai', link: ASCENDA_AMEX_HYUNDAI },
            { description: 'JAL', link: ASCENDA_JAL },
            { description: 'China Eastern', link: ASCENDA_CHINA_EASTERN },
          ]}
          languages={[getSkillIcon('TypeScript'), getSkillIcon('SASS'), getSkillIcon('HAML'), getSkillIcon('Ruby')]}
          technologies={[
            getSkillIcon('Angular'),
            getSkillIcon('Rails'),
            getSkillIcon('RSpec'),
            getSkillIcon('PSQL'),
            getSkillIcon('Git'),
            getSkillIcon('Github'),
          ]}
        />

        <ExperienceItem
          title="Software Engineer Intern"
          company="CVWO"
          companyUrl={CVWO}
          period="May 2021 - Aug 2021"
          points={[
            <li key={1}>
              Refactored the React frontend and Rails backend of the GIC Sparks & Smiles (Sparks) application, resulting
              in a more than 10x decrease in loading times, enabling efficient mentoring of youths from high-needs
              households by dedicated volunteers.
            </li>,
            <li key={2}>
              Enhanced Sparks' continuous integration pipeline by implementing Docker layer caching, resulting in a
              notable 50% reduction in pipeline run-time, significantly boosting developer productivity by enabling
              faster CI runtimes.
            </li>,
            <li key={3}>
              Migrated the Rails backend of the Active Ageing Centres (AAC) application to Go, seamlessly integrating
              production data with the enhanced Go API to facilitate a smooth transition of 10 eldercare centers onto
              the new backend infrastructure.
            </li>,
            <li key={4}>
              Streamlined volunteer matching for the AAC application by developing new React pages and Go API endpoints,
              enabling social workers to efficiently match volunteers with lower-income seniors, thereby reducing
              management overhead.
            </li>,
          ]}
          linkDescription="We wrote detailed write-ups on our work during the summer. They can be found here."
          links={[
            { description: 'AAC', link: AAC },
            { description: 'Sparks', link: SPARKS },
          ]}
          languages={[getSkillIcon('TypeScript'), getSkillIcon('Go'), getSkillIcon('Ruby')]}
          technologies={[
            getSkillIcon('React'),
            getSkillIcon('Redux'),
            getSkillIcon('Rails'),
            getSkillIcon('RSpec'),
            getSkillIcon('PSQL'),
            getSkillIcon('Docker'),
            getSkillIcon('Git'),
            getSkillIcon('Gitlab'),
            getSkillIcon('Digital Ocean'),
          ]}
        />
      </Element>
    </div>
  );
};

export default Experience;
