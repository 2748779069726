import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import ScrollToTop from 'react-scroll-to-top';
import useLocalStorage from 'react-use-localstorage';
import './App.scss';
import ScrollBtnSvg from './assets';
import splash from './assets/splash.json';
import { Navbar } from './components';
import Footer from './components/footer/Footer';
import images from './constants/image';
import { About, Contact, Experience, Intro, Project } from './containers';

const App = () => {
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(true);

  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const loadImage = (imageUrl: string) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = imageUrl;
        resolve(imageUrl);
        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(Object.entries(images).map((item) => loadImage(item[1])))
      .then(() => {
        setTimeout(() => {
          setIsAnimationPlaying(false);
          setTimeout(() => {
            document.body.style.overflow = 'scroll';
          }, 500);
        }, 2000);
      })
      .catch((err) => console.log('Failed to load images', err));
  }, []);

  return (
    <main id="main" data-theme={theme}>
      <AnimatePresence>
        {isAnimationPlaying && (
          <motion.div
            className="app-animation-outer"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="app-animation">
              <Lottie
                autoplay={true}
                loop={false}
                initialSegment={[0, 100]}
                className="app-lottie-animation"
                animationData={splash}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {!isAnimationPlaying && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 0.5 }}>
          <div className="app-contents">
            <Navbar
              isDarkMode={theme === 'dark'}
              setIsDarkMode={() => (theme === 'dark' ? setTheme('light') : setTheme('dark'))}
            />

            <Intro />

            <About />

            {/* <Skill /> */}

            <Experience />

            <Project />

            {/* <Other /> */}

            <Contact />

            <Footer />

            <MediaQuery query="(max-width: 1099px)">
              <ScrollToTop
                smooth
                component={<ScrollBtnSvg />}
                style={{
                  backgroundColor: 'var(--text-color)',
                  borderRadius: '50%',
                  height: '40px',
                  width: '40px',
                  right: '20px',
                }}
              />
            </MediaQuery>

            <MediaQuery query="(min-width: 1100px) and (max-width: 2600px)">
              <ScrollToTop
                smooth
                component={<ScrollBtnSvg />}
                style={{
                  backgroundColor: 'var(--text-color)',
                  borderRadius: '50%',
                  height: '40px',
                  width: '40px',
                  right: '80px',
                }}
              />
            </MediaQuery>
          </div>
        </motion.div>
      )}
    </main>
  );
};

export default App;
